<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      screenWidth: null,
    }
  },

  mounted() {
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
      // 当前设备是移动设备
      setTimeout(() => {
        this.toH5()
      }, 1000)
    }
    this.WebLocation()
  },

  methods: {
    toH5() {
      if (window.location.href.includes('dev')) {
        window.location.href = 'http://eamaster-mobile.dev.carlinx.cn/'
      } else {
        window.location.href = 'http://mobile.eamaster.com'
      }
    },
    WebLocation() {
      //判断浏览器的首选语言
      let lan =
        navigator.systemLanguage ||
        navigator.language ||
        navigator.browserLanguage
      lan = lan.toLowerCase()
      let description = ''
      let title = ''
      if (lan.indexOf('zh') !== -1) {
        title = '课外大师'
        description =
          'EAmaster课外大师是一个为7至12年级学生提供课外活动信息的平台，专注于为准备出国留学的青少年提供广泛且可靠的活动信息。通过各种渠道的悉心收集，我们的网站及小程序所罗列的活动横跨十多个类别，包括暑期课程、学术竞赛、商业竞赛、科学研究和社区服务等，这些活动都经过我们团队成员的严格审查和筛选。'
      } else {
        title = 'EAmaster'
        description =
          'EAmaster is an information platform for information on extracurricular activities for students in grades 7 to 12, dedicated to providing a reliable and extensive directory of enrichment programs for youth preparing to study abroad. We gather extracurricular activities for students from a variety of sources.  The activities on our website and WeChat mini program, which span over ten categories including summer programs, academic competitions, business competitions, scientific research, and community service, etc., are rigorously reviewed and screened by our team members.'
      }
      document.title = title
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', description)

      if (lan.indexOf('zh') == -1) {
        this.$router.push('english')
      }
    },
  },
}
</script>

<style lang="less">
#app {
  min-width: 1280px;
}

@font-face {
  font-family: AlibabaPuHuiTiR; // 定义字体的名称
  src: url('~@/assets/fonts/Alibaba-PuHuiTi-Regular.otf'),
    url('~@/assets/fonts/AlibabaSans-Regular.otf') // 字体来源
;
}

@font-face {
  font-family: AlibabaPuHuiTiM; // 定义字体的名称
  src: url('~@/assets/fonts/Alibaba-PuHuiTi-Medium.otf'),
    url('~@/assets/fonts/AlibabaSans-Medium.otf') // 字体来源
;
}
</style>
