<template>
  <div class="contain">
    <div class="content">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
    <div class="page_footer" v-if="!path">
      <div class="contact_me_wrap">
        <div class="mail_image">
          <img src="../assets/email_icon.png" alt="" />
        </div>
        <div class="mail_info">
          <div>联系我们</div>
          <div>
            <a href="mailto:contact@eamaster.com">contact@eamaster.com</a>
          </div>
        </div>
      </div>
      <div class="other_info_wrap">
        <div class="logo_image_wrap">
          <div class="logo_image">
            <img src="../assets/eamaster_logo_long.png" alt="" />
          </div>
          <div class="logo_image_text">
            我们是InternationalACAC、ChinaICAC会员，ChinaICAC白金合作伙伴
          </div>
        </div>
        <div class="about_tag">
          <!--  <div class="about_tag_item" @click="() => { toAboutPage('intro') }">平台简介</div>
          <div class="about_tag_item" @click="() => { toAboutPage('partners') }">合作伙伴</div>
          <div class="about_tag_item" @click="() => { toAboutPage('business') }">商务合作</div>
          <div class="about_tag_item" @click="() => { toAboutPage('website') }">网站服务</div> -->
        </div>
        <div class="qc_image_wrap">
          <div class="qc_image_text">扫码关注公众号</div>
          <div class="qc_image">
            <img src="../assets/pic_erweima.png" alt="" />
          </div>
        </div>
      </div>
      <!--   <div class="text_info">
        <div class="text_item">
          <img src="../assets/phone_icon.png" alt="" />
          <div>全国统一服务热线：400-011-2901</div>
        </div>
        <div class="text_item">
          <img src="../assets/time_icon.png" alt="" />
          <div>客服服务时间：工作日9:00-18:00</div>
        </div>
        <div class="text_item">
          <img src="../assets/address_icon.png" alt="" />
          <div>联系地址：浙江省杭州市西湖区杭大路15号嘉华国际1516</div>
        </div>
      </div> -->
      <div class="line_wrap"></div>
      <div class="station_flex">
        <div class="station_info" style="margin-right: 40px">
          Copyright© 2023 杭州课外信息技术有限公司 保留所有权利
        </div>
        <div class="station_info">
          <a
            href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602005404"
          >
            浙ICP备15045516号 浙公网安备 33010602005404号
          </a>
        </div>
      </div>
    </div>

    <div class="page_footer" style="min-width: 1920px" v-else>
      <div class="contact_me_wrap">
        <div class="mail_image">
          <img src="../assets/email_icon.png" alt="" />
        </div>
        <div class="mail_info">
          <div>Contact us</div>
          <div>
            <a href="mailto:contact@eamaster.com">contact@eamaster.com</a>
          </div>
        </div>
      </div>
      <div class="other_info_wrap">
        <div class="logo_image_wrap">
          <div class="logo_image">
            <img src="../assets/eamaster_logo_long.png" alt="" />
          </div>
          <div class="logo_image_text" style="letter-spacing: -0.5px">
            We are member of the International ACAC and sponsor of the 2016
            Conference
          </div>
        </div>
        <div class="about_tag">
          <!--  <div class="about_tag_item" @click="() => { toAboutPage('intro') }">平台简介</div>
          <div class="about_tag_item" @click="() => { toAboutPage('partners') }">合作伙伴</div>
          <div class="about_tag_item" @click="() => { toAboutPage('business') }">商务合作</div>
          <div class="about_tag_item" @click="() => { toAboutPage('website') }">网站服务</div> -->
        </div>
        <div class="qc_image_wrap">
          <div class="qc_image_text">scan to follow us</div>
          <div class="qc_image">
            <img src="../assets/pic_erweima.png" alt="" />
          </div>
        </div>
      </div>
      <!--  <div class="text_info">
        <div class="text_item">
          <img src="../assets/phone_icon.png" alt="" />
          <div>Unified national service hotline：400-011-2901</div>
        </div>
        <div class="text_item">
          <img src="../assets/time_icon.png" alt="" />
          <div>Customer service time：on weekdays9:00-18:00</div>
        </div>
      </div>
      <div class="text_info">
        <div class="text_item">
          <img src="../assets/address_icon.png" alt="" />
          <div>
            Address：1516 Jiahua International, No.15 Hangda Road, Xihu
            District, Hangzhou, Zhejiang Province, China
          </div>
        </div>
      </div> -->
      <div class="line_wrap"></div>
      <div class="station_flex">
        <div class="station_info" style="margin-right: 40px">
          Copyright© 2023 Hangzhou Kewai Information Technology Co., Ltd. All
          rights reserved.
        </div>
        <div class="station_info">
          <a
            href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602005404"
          >
            Zhejiang ICP No. 15045516 Zhejiang Public Network Security No.
            33010602005404
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      path: false,
    }
  },
  mounted() {},
  watch: {
    $route(v) {
      if (v.path == '/english') {
        this.path = true
      } else {
        this.path = false
      }
    },
  },
  methods: {
    toAboutPage(info) {
      this.$router.push({
        path: 'about#' + info,
      })
    },
  },
}
</script>

<style lang="less" scoped>
.content {
  background-color: #f6f7f7;
}

.page_footer {
  width: 100vw;
  background: #212330;
  padding-bottom: 32px;
  min-width: 1280px;

  .contact_me_wrap {
    width: 100%;
    height: 170px;
    background: #ff7200;
    display: flex;
    justify-content: center;
    align-items: center;

    .mail_image img {
      height: 110px;
      width: 124px;
    }

    .mail_info {
      margin-left: 60px;
      font-size: 24px;
      font-family: AlibabaPuHuiTiR;
      color: #ffffff;
      line-height: 36px;
      a {
        color: #ffffff;
      }
    }
  }

  .other_info_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;

    .logo_image_wrap {
      width: 601px;

      .logo_image img {
        width: 601px;
        height: 72px;
      }

      .logo_image_text {
        margin-top: 12px;
        height: 22px;
        font-size: 16px;
        font-family: AlibabaPuHuiTiR;
        color: rgba(255, 255, 255, 0.5);
        line-height: 22px;
        text-align: center;
      }
    }

    .about_tag {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 368px;
      margin-left: 81px;
      margin-right: 60px;

      .about_tag_item {
        height: 20px;
        font-size: 14px;
        font-family: AlibabaPuHuiTiR;
        color: #ffffff;
        line-height: 20px;
        cursor: pointer;
      }
    }

    .qc_image_wrap {
      // width: 90px;
      text-align: center;

      .qc_image_text {
        height: 17px;
        font-size: 12px;
        font-family: AlibabaPuHuiTiR;
        color: #ffffff;
        line-height: 17px;
        text-align: center;
      }

      .qc_image img {
        height: 90px;
        width: 90px;
      }
    }
  }

  .text_info {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 8px;

    .text_item {
      display: flex;
      justify-content: center;
      align-items: center;

      > img {
        height: 16px;
        width: 16px;
        margin-right: 12px;
      }

      > div {
        height: 20px;
        font-size: 14px;
        font-family: AlibabaPuHuiTiR;
        color: #ffffff;
        line-height: 20px;
      }
    }

    .text_item + .text_item {
      margin-left: 80px;
    }
  }
  .line_wrap {
    width: 1200px;
    height: 1px;
    background: rgba(255, 255, 255, 0.1);
    margin: 0 auto;
  }
  .station_flex {
    display: flex;
    padding-top: 5px;
    justify-content: center;
  }

  .station_info {
    // width: 100%;
    text-align: center;
    margin-top: 16px;
    height: 20px;
    font-size: 14px;
    font-family: AlibabaPuHuiTiR;
    color: rgba(255, 255, 255, 0.5);
    line-height: 20px;
    a {
      text-align: center;
      font-size: 14px;
      font-family: AlibabaPuHuiTiR;
      color: rgba(255, 255, 255, 0.5);
      line-height: 20px;
    }
  }
}
</style>
